@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS para el icono de Loading */
:root{
  --offset: 187;
  --duration: 1.4s;
}

* {
  font-family: sans-serif;
}

.ff {
  font-family: unset;
  font-size: 20px;
  font-weight: 600;
}

.spinner {
  animation: rotator var(--duration) linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: var(--offset);
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash var(--duration) ease-in-out infinite,
    colors (var(--duration)*4) ease-in-out infinite;
}

@keyframes colors {
	0% { stroke: #4285F4; }
	25% { stroke: #DE3E35; }
	50% { stroke: #F7C223; }
	75% { stroke: #1B9A59; }
  100% { stroke: #4285F4; }
}

@keyframes dash {
  0% { stroke-dashoffset: var(--offset); }
  50% {
    stroke-dashoffset: var(--offset)/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: var(--offset);
    transform:rotate(450deg);
  }
}
/* CSS para el icono de Loading */

.apple-icon{
  fill: white;
  object-fit: cover;
  border-radius: 50%;
  width: 32.5px;
  padding: 0 5px ;
}

.facebook-icon{
  fill: #1778f2;
}

.google-icon{
  background-color: #AA0000;
  fill: white;
  border-radius: 50%;
  padding: 5px;
}

.mini-facebook-icon{
  fill: #0078A7;
  position: relative;
  height: 30px;
  left: 5px;
}

.mini-instagram-icon{
  fill: #0078A7;

}

.mini-twitter-icon{
  fill: #0078A7;
}
.invalid {
  border:1px solid #ff2b2b !important;
 }
 input, textarea {
  outline: none !important;
  box-shadow: none !important; 
}
.text-danger {
  color: #ff2b2b;
  font-size: 12px;
}
#firstName, #lastName{
  text-transform: capitalize;
}

#firstName::-webkit-input-placeholder, #lastName::-webkit-input-placeholder {
  text-transform: none;
}
#country {
  background: transparent;
}

select {
  background: transparent;
}

.k-i-loading.k-example-loading {
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(144,152,165);
}

.k-i-loading.k-example-loading::before,
.k-i-loading.k-example-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  box-sizing: inherit;
  border-radius: 50%;
  border-width: .05em;
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}
.k-icon.k-i-loading.k-example-loading::before,
.k-icon.k-i-loading::after {
  content: "";
}

.k-i-loading.k-example-loading::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation .7s linear infinite;
}

.k-i-loading.k-example-loading::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: .5em;
  height: .5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}

.example-wrapper {
  min-height: 280px;
  align-content: flex-start;
}
.example-wrapper p, .example-col p {
  margin: 20px 0 10px;
}
.example-wrapper p:first-child, .example-col p:first-child {
  margin-top: 0;
}
.example-col {
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding-bottom: 20px;
}
.example-config {
  margin: 0 0 20px;
  padding: 20px;
  background-color: rgba(0,0,0,.03);
  border: 1px solid rgba(0,0,0,.08);
}
.event-log {
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
  list-style-type: none;
  border: 1px solid rgba(0,0,0,.08);
  background-color: white;
}
.event-log li {
  margin: 0;
  padding: .3em;
  line-height: 1.2em;
  border-bottom: 1px solid rgba(0,0,0,.08);
}
.event-log li:last-child {
  margin-bottom: -1px;
}

#eye {
    position: relative;
    margin-top: -25px;
    float: right;
    padding-right: 10px;
    z-index: 9;
}

.k-dateinput  {
  padding: 0px !important;
}
.k-datepicker {
  padding: 3px 17px !important;
}

#birthDate {
  padding: 0px !important;
}
#phone {
  border: 1px solid rgb(0, 0, 0, 0.60);
  color: #424242;
  padding: 7px 17px !important;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
}
.react-tel-input {
  width: 100%;
}

#gender_label {
  margin-top: -15px;
}

#phone {
  padding: 0px !important;
}

input::-webkit-input-placeholder {
  font-size: 14px;
}

@media (max-width: 768px) {
  input::-webkit-input-placeholder {
    font-size: 13px;
  }
}
